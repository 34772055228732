import styled from "styled-components";

export const Wrapper = styled.div`
    padding-left: 30px;
    padding-right: 30px;
    background: #8E966F;
    height: 100vh;
    position: fixed;

    .contents {
       height: 100vh;
    }
`